import React from 'react';

export default function () {
    return <section>
        <article className="w-60">
            <h2>Pressurization</h2>
            <p>Air exiting the FPS unit creates a positive flow of oxygen inside a properly sealed cab. This positive flow pushes clean air out of the leaks in the micro-environment side of the system, rather than allowing contaminated air to enter. A differential pressure gauge is available to measure the difference in pressure between the inside and outside of the cab, leading to better flow rates and improved filter life.</p>

            <a>CAF Recommnended Flow Rate</a>
            <p><span className="bolded">CAUTION:</span> <em>The enclosure MUST have positive air flow, greater than 18 Cubic Feet per Minute (CFM). Standard EN15695 requires a minimum cab pressure of 20 Pascal (PA), or 0.08” of water.</em></p>

            {/* <p>The FPS55 Series is a compact unit that is easy to install and requires minimal space.</p>

            <p>Every FPS55 series pressurizer is factory vapor tested for integrity at .00038 micron utilizing CAF’s patented test procedure.</p>

            <p>This same procedure, along with particulate testing, can be used to completely characterize environmental enclosures in the field, (CDC Mining Publication).</p >

            <p>CAF has proven technology with over 25 years of experience.</p> */}

            <a>FPS Installation Instruction Manual</a>
            <a>FPS PARTICULATE/VAPOR FILTERS</a>
        </article>
    </section>;
}